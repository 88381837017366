import { useAnySidebar } from 'ce-mfe-shared-features';
import useOfferScopeSidebar from 'hooks/useOfferScopeSidebar/useOfferScopeSidebar';
import useSidebarCMS from 'hooks/useSidebarCMS/useSidebarCMS';
import useSidebarExperimentationPlatform from 'hooks/useSidebarExperimentationPlatform/useSidebarExperimentationPlatform';
import useSidebarForce from 'hooks/useSidebarForce/useSidebarForce';
import { useLocation } from 'react-router-dom';

const sidebars = {
  cms: useSidebarCMS,
  offer: useOfferScopeSidebar,
  experimentationPlatform: useSidebarExperimentationPlatform,
  force: useSidebarForce,
  engage: useAnySidebar,
  audience: useAnySidebar
};

function useSidebarWithParam() {
  const location = useLocation();
  const QUERY_NAME = 'origin';
  const MAIN_SIDEBAR = 'audience';

  const value = new URLSearchParams(location.search).get(QUERY_NAME);

  const sidebar = value || MAIN_SIDEBAR;

  sidebars[sidebar](sidebar);

  return null;
}

export default useSidebarWithParam;
