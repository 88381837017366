import { globalCss, ThemeProvider as HexaUiTheme } from '@hexa-ui/theme';
import { ReactNode } from 'react';
import { ThemeContainer } from './ThemeProvider.styles';
interface HexaProviderProps {
  children: ReactNode;
}
const GlobalStyles = globalCss();
export default function ThemeProvider({ children }: Readonly<HexaProviderProps>) {
  GlobalStyles();
  return (
    <HexaUiTheme theme="tadaSecondary">
      <ThemeContainer type="fluid" sidebar>
        {children}
      </ThemeContainer>
    </HexaUiTheme>
  );
}
