import { DebitCard, Home, Megaphone, Store, Users } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import useBeesProvider from 'context/BeesContext/BeesContext';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useSidebarCMS = () => {
  const { formatMessage } = useIntl();
  const { country } = useBeesProvider();

  const MI_NEGOCIO_HOME = {
    id: 'mi-negocio',
    title: formatMessage({ id: 'sidebarCMS.Home' }),
    path: '/cms/home',
    icon: () => <Home />
  };

  const BEES_CUSTOMER = {
    id: 'test-1',
    title: formatMessage({ id: 'sidebarCMS.BeesCustomer' }),
    path: '/cms/banners-launch-list/customer',
    icon: () => <DebitCard />
  };

  const BEES_ENGAGE = {
    id: 'engage',
    title: formatMessage({ id: 'sidebarCMS.BeesEngage' }),
    path: '/cms/banners-launch-list/engage-loading',
    icon: () => <Megaphone />
  };

  const MI_NEGOCIO = {
    id: 'mi-negocio-content-list',
    title: formatMessage({ id: 'sidebarCMS.MiNegocio' }),
    path: '/cms/mi-negocio-content-list',
    icon: () => <Store />
  };

  const AUDIENCE_EDITOR = {
    id: 'audience-editor',
    title: formatMessage({ id: 'sidebarCMS.AudienceEditor' }),
    path: '/audiences',
    icon: () => <Users />,
    onPageChange: () => {
      window.location.href = '/audiences?origin=cms';
    }
  };

  const items = useMemo(() => {
    return country !== 'US'
      ? [MI_NEGOCIO_HOME, BEES_CUSTOMER, BEES_ENGAGE, MI_NEGOCIO, AUDIENCE_EDITOR]
      : [MI_NEGOCIO_HOME, BEES_CUSTOMER, AUDIENCE_EDITOR];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useSidebar({
    items,
    utils: []
  });

  return items;
};

export default useSidebarCMS;
