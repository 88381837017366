import { Outlet } from 'react-router-dom';
import useSidebarWithParam from '../../hooks/useSidebarWithParam/useSidebarWithParam';

const DefaultLayout = () => {
  useSidebarWithParam();

  return <Outlet />;
};

export default DefaultLayout;
