import Api from 'Api/Api';
import { useLogService as logService } from 'admin-portal-shared-services';
import { GET_SUPPORTED_COUNTRIES } from 'services/Endpoints';
import { GetVendorRequest } from './VendorServices.d';

const getVendors = async ({ mock }: GetVendorRequest) => {
  const { error: errorLog } = logService();

  try {
    const { data } = await Api.get({
      url: GET_SUPPORTED_COUNTRIES,
      mock: mock
    });
    return data;
  } catch (error) {
    errorLog('Get audiences - ', error);
  }
};
export default getVendors;
