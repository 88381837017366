import { List, Users } from '@hexa-ui/icons';
import { SidebarItem, useAuthenticationService, useSidebar } from 'admin-portal-shared-services';

import { useIntl } from 'react-intl';
import { PREFIXES, SIDEBAR_ITEMS } from './useOfferScopeSidebar.types';

export const IconList = (): React.ReactElement => <List />;
export const IconUsers = (): React.ReactElement => <Users />;

const useOfferScopeSidebar = (name: string) => {
  const authentication = useAuthenticationService();
  const userScopes = authentication.parseJwt()?.scopes ?? [];

  const { formatMessage } = useIntl();

  const handleRedirect = (path: string) => {
    let url = window.location.origin;
    url = `${url}${path}`;
    window.location.href = url;
  };

  const OFFER_ITEM = {
    id: SIDEBAR_ITEMS.OFFER_CREATION,
    title: formatMessage({ id: 'sidebarOffer.OfferList' }),
    icon: IconList,
    path: '/offer-creation'
  };

  const AUDIENCE_ITEM = {
    id: SIDEBAR_ITEMS.AUDIENCE_EDITOR,
    title: formatMessage({ id: 'sidebarOffer.AudienceBuilder' }),
    icon: IconUsers,
    path: '/audiences',
    onPageChange() {
      handleRedirect(`/audiences?origin=${name}`);
    }
  };

  const getItemsToRender = () => {
    const items: SidebarItem[] = [OFFER_ITEM];

    userScopes.forEach((currentScope) => {
      const userPrefix = currentScope.split('.')[0];

      if (PREFIXES.includes(userPrefix) && !items.includes(AUDIENCE_ITEM)) {
        items.push(AUDIENCE_ITEM);
      }
    });

    return items;
  };

  const items = userScopes.length ? getItemsToRender() : [];

  useSidebar({ items, utils: [] });

  return items;
};

export default useOfferScopeSidebar;
