import { ReactNode } from 'react';

interface LoadingRenderProps {
  isLoading: boolean;
  children: ReactNode;
  fallBack: ReactNode;
}
const LoadingRender = ({ children, isLoading, fallBack }: LoadingRenderProps) => {
  return <>{isLoading ? fallBack : children}</>;
};

export default LoadingRender;
