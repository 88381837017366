import { useAuthenticationService } from 'admin-portal-shared-services';
import { InternalAxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';

export function HeaderConfig(config: InternalAxiosRequestConfig) {
  const { headers } = config;

  const authentication = useAuthenticationService();
  const token = authentication.getAuthHeader();

  headers.Authorization = token;
  headers.requestTraceId = uuid();

  return { ...config, headers };
}
