import { useAppHeader } from 'admin-portal-shared-services';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import Loader from 'components/Loader/Loader';
import LoadingRender from 'components/LoadingRender/LoadingRender';
import { VendorServicesResponse } from 'services/vendors/VendorServices.d';
import {
  LocalStorageVendor,
  getLocalStorageSelectedVendor,
  setLocalStorageSelectedVendor
} from 'utils/LocalStorage/hubHeaderData';
import { BeesLoading } from './BeesContext.styles';
import { useGetSupportedCountries } from './hooks/useGetSupportedCountries/useGetSupportedCountries';

interface BeesContextProps {
  countryOptions: string[];
  country: string;
  loadingScreen: boolean;
  hasVendorSelection: boolean;
  selectedVendor: LocalStorageVendor;
}
interface BeesProviderProps {
  children: ReactNode;
}

interface VendorProperties {
  id: string;
  country: string;
  isManufacturer: boolean;
}

const initState: BeesContextProps = {
  countryOptions: [],
  country: 'BR',
  hasVendorSelection: false,
  loadingScreen: false,
  selectedVendor: {
    selectedVendor: '',
    code: '',
    abiVendorId: '',
    allowVendorSelection: false,
    isManufacturer: false
  }
};

export const BeesContext = createContext<BeesContextProps>(initState);

export const BeesProvider = ({ children }: BeesProviderProps) => {
  const [headerState] = useAppHeader();

  const { data: supportedCountriesArray, loading: loadingSupportedCountries } =
    useGetSupportedCountries();

  const [vendorState, setVendorState] = useState<LocalStorageVendor | null>(
    getLocalStorageSelectedVendor()
  );

  const updateVendorABI = useCallback((vendor: VendorServicesResponse) => {
    const newVendor: LocalStorageVendor = {
      ...vendor,
      selectedVendor: vendor.abiVendorId
    };
    setLocalStorageSelectedVendor(newVendor);
    setVendorState(newVendor);
  }, []);

  const getVendorFromSelectedCountry = useCallback(() => {
    return supportedCountriesArray?.find((country) => country.code === headerState.selectedCountry);
  }, [supportedCountriesArray, headerState.selectedCountry]);

  const getVendorFromVendorSelector = useCallback((): LocalStorageVendor => {
    const vendor = headerState.vendorOptions.find(
      (vendor) => vendor.id === headerState.selectedVendor
    ) as VendorProperties;
    const abiVendorId = getVendorFromSelectedCountry().abiVendorId;
    return {
      abiVendorId,
      selectedVendor: vendor.id,
      allowVendorSelection: true,
      code: vendor.country,
      isManufacturer: vendor.isManufacturer
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerState.selectedVendor, headerState.vendorOptions, vendorState]);

  const hasChangedCountry = useMemo(() => {
    const hasSupportedCountries = !!supportedCountriesArray?.length;
    const hasSelectedCountry = !!headerState.selectedCountry;
    const hasSelectedVendor = !!vendorState;
    const isCountryDifferentFromVendor = headerState.selectedCountry !== vendorState.code;

    return (
      hasSupportedCountries &&
      hasSelectedCountry &&
      hasSelectedVendor &&
      isCountryDifferentFromVendor
    );
  }, [supportedCountriesArray, headerState.selectedCountry, vendorState]);

  const vendorSelectionAllowed = useMemo(
    () => headerState.vendorSelect,
    [headerState.vendorSelect]
  );

  const loadingScreen = useMemo(() => {
    return loadingSupportedCountries;
  }, [loadingSupportedCountries]);

  useEffect(() => {
    if (!vendorState || vendorState.selectedVendor === '') {
      const defaultVendor = {
        selectedVendor: '',
        code: '',
        abiVendorId: '',
        allowVendorSelection: false,
        isManufacturer: false
      };

      if (JSON.stringify(vendorState) !== JSON.stringify(defaultVendor)) {
        setLocalStorageSelectedVendor(defaultVendor);
        setVendorState(defaultVendor);
      }
    }
  }, [vendorState]);

  useEffect(() => {
    if (loadingScreen) return;

    const vendorLocalStorage = getLocalStorageSelectedVendor();
    const isFirstAccess = !vendorLocalStorage.code && !vendorLocalStorage.selectedVendor;
    if (isFirstAccess && supportedCountriesArray?.length > 0) {
      updateVendorABI(supportedCountriesArray[0]);
      return;
    }

    if (hasChangedCountry || (!vendorSelectionAllowed && selectedVendorIsDifferentFromState)) {
      const vendor = getVendorFromSelectedCountry();
      updateVendorABI(vendor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingScreen, supportedCountriesArray, vendorSelectionAllowed, hasChangedCountry]);

  const selectedVendorIsDifferentFromState = useMemo(
    () => headerState.selectedVendor && headerState.selectedVendor !== vendorState.selectedVendor,
    [headerState.selectedVendor, vendorState.selectedVendor]
  );

  useEffect(() => {
    if (loadingScreen || headerState.loadingVendors) return;

    if (vendorSelectionAllowed && selectedVendorIsDifferentFromState) {
      const vendor = getVendorFromVendorSelector();
      setLocalStorageSelectedVendor(vendor);
      setVendorState(vendor);
    }
  }, [
    vendorSelectionAllowed,
    selectedVendorIsDifferentFromState,
    loadingScreen,
    headerState.selectedVendor,
    getVendorFromVendorSelector,
    headerState.loadingVendors
  ]);

  const memoValue = useMemo((): BeesContextProps => {
    const countryOptions = supportedCountriesArray?.map((country) => country.code);
    const country = vendorState.code;
    return {
      countryOptions,
      country,
      loadingScreen,
      selectedVendor: vendorState,
      hasVendorSelection: vendorSelectionAllowed
    };
  }, [supportedCountriesArray, vendorState, loadingScreen, vendorSelectionAllowed]);

  return (
    <BeesContext.Provider value={memoValue}>
      <LoadingRender
        isLoading={loadingScreen}
        fallBack={
          <BeesLoading>
            <Loader type="buzz" wrapperSize="page" />
          </BeesLoading>
        }
      >
        {children}
      </LoadingRender>
    </BeesContext.Provider>
  );
};

const useBeesProvider = (): BeesContextProps => {
  return useContext(BeesContext);
};

export default useBeesProvider;
