/* eslint-disable import/named */
import { useLogService } from 'admin-portal-shared-services';

interface ErrorBoundaryProps {
  err: Error;
}

export function ErrorBoundary({ err }: ErrorBoundaryProps) {
  useLogService().error(err);
  return null;
}
