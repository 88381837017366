import { Home, Users } from '@hexa-ui/icons';
import { useSidebar } from 'admin-portal-shared-services';
import useBeesProvider from 'context/BeesContext/BeesContext';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useSidebarExperimentationPlatform = () => {
  const { formatMessage } = useIntl();
  const { country } = useBeesProvider();

  const EXPERIMENTATION_PLATFORM_HOME = {
    id: 'experimentation-platform-home',
    title: formatMessage({ id: 'useSidebarExperimentationPlatform.Home' }),
    path: '/experimentation-platform',
    icon: () => <Home />
  };

  const AUDIENCE_EDITOR = {
    id: 'audience-editor',
    title: formatMessage({ id: 'useSidebarExperimentationPlatform.AudienceEditor' }),
    path: '/audiences',
    icon: () => <Users />,
    onPageChange: () => {
      window.location.href = '/audiences?origin=experimentationPlatform';
    }
  };

  const items = useMemo(() => {
    return [EXPERIMENTATION_PLATFORM_HOME, AUDIENCE_EDITOR];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useSidebar({
    items,
    utils: []
  });

  return items;
};

export default useSidebarExperimentationPlatform;
