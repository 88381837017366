// eslint-disable-next-line import/extensions
import { CustomAxiosRequestConfig } from 'admin-portal-shared-services/dist/services/authentication/AuthenticationService.types';
import Axios from 'axios';
import getApiHost from 'services/host/HostService';
import { HeaderConfig } from './ApiHeaderConfig';

export interface IRequest {
  url: string;
  mock?: boolean;
  body?: any;
  config?: CustomAxiosRequestConfig;
}

const initialAxios = Axios.create({ baseURL: '/' });

initialAxios.interceptors.request.use(HeaderConfig);

const Api = {
  post: async ({ url, body, mock, config }: IRequest): Promise<any> => {
    const uri = getApiHost(url, mock);
    return await initialAxios.post(uri, body, config).catch((error) => ({
      hasError: true,
      ...error
    }));
  },
  delete: ({ url, config }: IRequest): Promise<any> =>
    initialAxios.delete(url, config).catch((error) => ({ hasError: true, ...error })),

  get: async ({ url, mock, config }: IRequest): Promise<any> => {
    const uri = getApiHost(url, mock);
    return await initialAxios.get(uri, config).catch((error) => ({ hasError: true, ...error }));
  },

  put: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.put(url, body, config).catch((error) => ({
      hasError: true,
      ...error
    })),

  patch: ({ url, body, config }: IRequest): Promise<any> =>
    initialAxios.patch(url, body, config).catch((error) => ({
      hasError: true,
      ...error
    }))
};

export default Api;
