import { styled } from '@hexa-ui/theme';

export const WrapperLoader = styled('div', {
  variants: {
    wrapperSize: {
      default: {},
      page: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  }
});
