/* eslint-disable @typescript-eslint/ban-ts-comment */
import { appHeaderConfig } from 'admin-portal-shared-services';

export function setupLocalEnv(): void {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  if (window.Cypress) {
    //@ts-ignore
    window.changeCountry = (country) => {
      console.log('LOCAL ENV COUNTRY ==>', country);
      appHeaderConfig.onChangeSelectedCountry(country);
    };
    //@ts-ignore
    window.changeVendor = (vendor) => {
      console.log('LOCAL ENV VENDOR ==>', vendor);
      appHeaderConfig.onChangeSelectedVendor(vendor);
    };
  }
}
