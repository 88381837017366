import { LoadingBuzz, LoadingDots } from '@hexa-ui/components';
import { useMemo } from 'react';
import { WrapperLoader } from './Loader.styles';
export interface LoaderProps {
  type?: 'buzz' | 'dots';
  wrapperSize?: 'default' | 'page';
}

const Loader = ({ type, wrapperSize }: LoaderProps) => {
  const loaderIcon = useMemo(() => {
    if (type === 'buzz') return <LoadingBuzz dataTestId="loading-buzz" />;
    return <LoadingDots dataTestId="loading-dots" />;
  }, [type]);
  return <WrapperLoader wrapperSize={wrapperSize}>{loaderIcon}</WrapperLoader>;
};

Loader.defaultProps = {
  type: 'dots',
  wrapperSize: 'default'
};

export default Loader;
