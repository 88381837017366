import { VendorServicesResponse } from 'services/vendors/VendorServices.d';
import { VENDOR_LOCAL_STORAGE } from '../../constants';

export interface LocalStorageVendor extends VendorServicesResponse {
  selectedVendor: string;
}

export const EMPTY_VENDOR: LocalStorageVendor = {
  selectedVendor: '',
  code: '',
  abiVendorId: '',
  allowVendorSelection: false,
  isManufacturer: false
};

export const setLocalStorageSelectedVendor = (vendor: LocalStorageVendor) => {
  window.localStorage.setItem(VENDOR_LOCAL_STORAGE, JSON.stringify(vendor));
};

export const getLocalStorageSelectedVendor = (): LocalStorageVendor => {
  const vendor = window.localStorage.getItem(VENDOR_LOCAL_STORAGE);

  return vendor ? JSON.parse(vendor) : EMPTY_VENDOR;
};
