export const TRAIT_PRIORITY_RANGE_MIN_RANGE = 1;
export const TRAIT_PRIORITY_RANGE_MAX_RANGE = 10;

export const OPTIMIZELY_PROJECT_NAME = 'BEES Engage';

export const VENDOR_LOCAL_STORAGE = 'userInfo';

export const CSV_ID_HEADER = 'POC_ID';

export const QUERY_NAME = 'origin';

export const AUDIENCES_PATH = '/audiences';
export const MANAGE_AUDIENCES_PATTERN = /^\/audiences\/audience\/[^/]+$/;
export const NEW_AUDIENCES_PATH = '/audiences/new-audience';
export const EXPORTED_FILES_PATH = '/audiences/exported-files';
