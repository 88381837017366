import Loader from 'components/Loader/Loader';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DefaultLayout from './DefaultLayout/DefaultLayout';

const AudiencePage = lazy(() => import('pages/AudiencePage/AudiencePage'));

const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));

const Router = () => {
  return (
    <Suspense fallback={<Loader wrapperSize="page" />}>
      <BrowserRouter>
        <Routes>
          <Route path="audiences" element={<DefaultLayout />}>
            <Route index element={<DashboardPage />} />
            <Route path="new-audience" element={<AudiencePage />} />
            <Route path="audience/:id" element={<AudiencePage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default Router;
