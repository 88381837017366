import { useQuery } from 'react-query';
import getVendors from 'services/vendors/VendorServices';
import { IGetSupportedCountriesReturn } from './useGetSupportedCountries.d';

export const useGetSupportedCountries = (): IGetSupportedCountriesReturn => {
  const {
    data,
    error,
    isLoading: loading,
    refetch
  } = useQuery('get-vendors', () => getVendors({ mock: false }), {
    initialData: []
  });
  return { data, error, loading, reFetch: refetch };
};
